import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'

Vue.prototype.$http = axios
ElementUI.Dialog.props.closeOnClickModal.default = false;

Vue.config.productionTip = false;
Vue.use(ElementUI);
ElementUI.MessageBox.setDefaults({
    closeOnClickModal: false,
});

function uuid () {
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';
    return s.join('');
}

// 时间格式化
Date.prototype.format = function (fmt) {
    if (fmt === null || fmt === undefined) {
        fmt = 'yyyy-MM-dd hh:mm:ss';
    }
    const o = {
        'M+': this.getMonth() + 1,                 //月份
        'd+': this.getDate(),                    //日
        'h+': this.getHours(),                   //小时
        'm+': this.getMinutes(),                 //分
        's+': this.getSeconds(),                 //秒
        'q+': Math.floor((this.getMonth() + 3) / 3), //季度
        'S': this.getMilliseconds()             //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        }
    }
    return fmt;
};


Vue.prototype.$arrayLookup = (data, key, value, targetKey) => {
    let targetValue = '';
    for (let i = 0; i < data.length; i++) {
        if (data[i][key] === value) {
            targetValue = data[i][targetKey];
            break;
        }
    }
    return targetValue;
};

Vue.prototype.$uuid = () => uuid();

let flag = true;
Vue.prototype.$print = (id, close) => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('trident') > -1) {
        alert('请使用google或者360浏览器打印');
        return false;
    } else if (userAgent.indexOf('msie') > -1) {
        const onlyChoseAlert = simpleAlert({
            'content': '请使用Google或者360浏览器打印',
            'buttons': {
                '确定': function () {
                    onlyChoseAlert.close();
                }
            }
        });
        alert('请使用google或者360浏览器打印');
        return false;
    } else {
        document.getElementById('app').style.display = 'none';

        const context = document.createElement('div');
        context.className = 'print_style_a4';
        context.innerHTML = document.getElementById(id).innerHTML;

        const closeBtn = document.createElement('button');
        closeBtn.innerText = '取消';
        closeBtn.className = 'print_style_btn print_style_btn_white';
        closeBtn.style.right = '135px';

        const printBtn = document.createElement('button');
        printBtn.innerText = '打印';
        printBtn.className = 'print_style_btn print_style_btn_blue';

        const closePage = () => {
            document.getElementById('app').style.display = 'unset';
            context.remove();
            closeBtn.remove();
            printBtn.remove();
            (typeof close === 'function' && close());
        };

        closeBtn.onclick = () => closePage();

        printBtn.onclick = () => {
            closeBtn.remove();
            printBtn.remove();
            if (flag) {
                flag = false;
                setTimeout(() => {
                    window.print();
                    document.getElementById('app').style.display = 'unset';
                    context.remove();
                    (typeof close === 'function' && close());
                }, 1000);
                return;
            }
            window.print();
            document.getElementById('app').style.display = 'unset';
            context.remove();
            (typeof close === 'function' && close());
        };

        document.body.appendChild(context);
        document.body.appendChild(closeBtn);
        document.body.appendChild(printBtn);
    }
};

Vue.prototype.$format = (date, fmt) => {
    return new Date((new Date(date)).getTime()).format(fmt || null);
};

Vue.prototype.$adminType = {
    0: '超级管理员',
    1: '管理员',
    2: '题库管理员',
};

Vue.prototype.$adminTypes = [
    // {
    //     role: 0,
    //     name: '超级管理员'
    // },
    {
        role: 1,
        name: '管理员'
    },
    {
        role: 2,
        name: '题库管理员'
    },
];


new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
